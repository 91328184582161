import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/oida-is-des-org-blog/oida-is-des-org-blog/src/templates/blog-post-template.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`What does it feel like to be blind? How can you navigate a website and read the content? My demo page
“Come to the Dark Side” allows you to turn everything black and experience the website as a blind user would.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.66666666666666%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAwACBP/EABUBAQEAAAAAAAAAAAAAAAAAAAIB/9oADAMBAAIQAxAAAAE99ixBNFf/xAAbEAACAQUAAAAAAAAAAAAAAAABAhAAAxESIf/aAAgBAQABBQJrSgjZY48Yr//EABYRAQEBAAAAAAAAAAAAAAAAAAEQMf/aAAgBAwEBPwEE2f/EABURAQEAAAAAAAAAAAAAAAAAAAEQ/9oACAECAQE/AWf/xAAaEAACAgMAAAAAAAAAAAAAAAABEAACESFR/9oACAEBAAY/AsVg4VsP/8QAGBABAQEBAQAAAAAAAAAAAAAAAREAITH/2gAIAQEAAT8hu5Gyy9xBnhiO4CVwDk3f/9oADAMBAAIAAwAAABDHD//EABcRAAMBAAAAAAAAAAAAAAAAAAABESH/2gAIAQMBAT8Qc6Kj/8QAFhEBAQEAAAAAAAAAAAAAAAAAAQAh/9oACAECAQE/EEJlt//EABwQAQEAAgIDAAAAAAAAAAAAAAERACExQVFx0f/aAAgBAQABPxAcRjZqLXoMT5aPIdI/TIKhmwqOal9zBAQhwBowuhn/2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Closeup of a person's eye",
          "title": "Closeup of a person's eye",
          "src": "/static/de7049abd912f7cb2473e2b20d75be07/e5166/pexels-victor-freitas-eye-closeup.jpg",
          "srcSet": ["/static/de7049abd912f7cb2473e2b20d75be07/f93b5/pexels-victor-freitas-eye-closeup.jpg 300w", "/static/de7049abd912f7cb2473e2b20d75be07/b4294/pexels-victor-freitas-eye-closeup.jpg 600w", "/static/de7049abd912f7cb2473e2b20d75be07/e5166/pexels-victor-freitas-eye-closeup.jpg 1200w", "/static/de7049abd912f7cb2473e2b20d75be07/b17f8/pexels-victor-freitas-eye-closeup.jpg 1600w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
`}<em parentName="p">{`Photo: © Victor Freitas / pexels.com`}</em></p>
    <h2>{`2.2 billion people have a vision impairment`}</h2>
    <p>{`According to the `}<a parentName="p" {...{
        "href": "https://www.who.int/publications/i/item/9789241516570"
      }}>{`“World report on vision 2019”`}</a>{`,
about 2.2 billion people around the world are blind or visually impaired. Many of them use a screen reader to
navigate the web.`}</p>
    <p>{`As a person that can see, it can be hard to truly understand what that means: No visual input, relying
only on the auditory feedback a screen reader provides.`}</p>
    <p>{`Because I'm a curious person, I put together a demo page that allows you to experience what it feels like
to be a blind web user. My goal is to help people get a better understanding of how important accessible web
content is for blind users.`}</p>
    <h2>{`Come to the Dark Side`}</h2>
    <p>{`I've included my `}<a parentName="p" {...{
        "href": "https://alexlehner86.github.io/come-to-the-dark-side/"
      }}>{`demo page`}</a>{` below.
Simply activate your screen reader and turn everything black via the "Go Dark" button in the header.`}</p>
    <iframe src="https://alexlehner86.github.io/come-to-the-dark-side/" title="Demo Page Come to the Dark Side"></iframe>
    <p>{`I look forward to your feedback! Just hit me up on `}<a parentName="p" {...{
        "href": "https://tech.lgbt/@alex86"
      }}>{`Mastodon`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      